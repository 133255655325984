import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const taskVuex = createNamespacedHelpers("task");
export default {
  name: "tasks",
  data() {
    const nTaskList = [{
      label: this.$lang("Join channel"),
      target: "channel",
      icon: "tp"
    }, {
      label: this.$lang("Folow us on X"),
      target: "twitter",
      value: 1,
      icon: "twitter"
    }, {
      label: this.$lang("Connect wallet"),
      target: "wallet",
      icon: "ton"
    }, {
      label: this.$lang("I am not a robot"),
      target: "facescan",
      icon: "facescan"
    }];
    return {
      nTaskList,
      popBindWalletShow: false,
      taskNum: 0,
      extraTaskNum: 0,
      checkNum: 0,
      timeVal: null,
      stimeVal: null,
      popFaceScanShow: false,
      itemData: {},
      popEarnTipShow: false,
      fadvList: []
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...taskVuex.mapState(["taskList", "advList", "selectTab"])
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.timeVal);
    clearTimeout(this.stimeVal);
    this.popBindWalletShow = false;
    this.popFaceScanShow = false;
    this.popEarnTipShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    "userData": {
      handler() {
        this.checkTask();
      },
      deep: true,
      immediate: true
    },
    "taskList": {
      handler(newVal, oldVal) {
        console.log("this.taskList", this.taskList);
        this.checkTask();
        this.getFadvList();
      },
      deep: true,
      immediate: true
    },
    "advList": {
      handler(newVal, oldVal) {
        console.log("this.advList", this.advList);
        this.checkTask();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...userVuex.mapActions(["join"]),
    ...userVuex.mapMutations(["setUserData"]),
    ...taskVuex.mapActions(['getTaskList', "getAdvList"]),
    ...taskVuex.mapMutations(['setSelectTab']),
    onSkip(page) {
      this.$router.push(`/${page}`);
    },
    async onJoin(item) {
      if (item.target == "channel" || item.target == "telegram") {
        this.WebApp.openTelegramLink(this.setting.socials[item.target]);
      } else if (item.target == "wallet") {
        this.popBindWalletShow = true;
        return;
      } else if (item.target == "facescan") {
        this.popFaceScanShow = true;
        return;
      } else {
        this.WebApp.openLink(this.setting.socials[item.target]);
      }
      if (!this.userData[`joined_${item.target}`]) {
        await this.join([item.target, null, 1]);
      }
    },
    async onExtralJoin(item, adv = 0) {
      if ((item.type || '') == 'adsgram' || (item.provider || '') == 'adsgram') {
        this.$loading.show();
        const AdController = await window.Adsgram.init({
          blockId: item.url
        });
        await AdController.show().then(result => {
          // user watch ad till the end
          // your code to reward user
          this.$loading.hide();
          if (result.done && !item.completed) this.checkExtralTask(item, adv);
        }).catch(result => {
          // user skipped video or get error during playing ad
          // do nothing or whatever you want
          console.log(result);
          this.$loading.hide();
        });
        this.$loading.hide();
        return;
      } else if ((item.type || '') == 'openad' || (item.provider || '') == 'openad') {
        this.$loading.show();
        const adParams = {
          version: '',
          // your app version if there is no data, please leave it blank
          TG: true // If it is required for Telegram TMA application, and the value is true.
        };

        let dataParams = item.url.split("|");
        const adInfo = {
          zoneId: Number(dataParams[0]),
          // example zoneId, please check your own code parameters
          publisherId: Number(dataParams[1]),
          // example publisherId, please check your own code parameters
          eventId: 0 // Reserved Parameter
        };

        const userInfo = {
          userId: "",
          // user Id, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
          firstName: "",
          // firstName or userId, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
          lastName: "",
          // lastName or userId, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
          userName: "",
          // username or userId, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
          walletType: "",
          // user wallet type: TON / EVM / TRON / other blockchain project names,  if there is no data, please leave it blank
          walletAddress: "" // user wallet address,  if there is no data, please leave it blank
        };

        let res = await window.openADJsSDK.interactive.init({
          adParams,
          adInfo,
          userInfo
        });
        this.$loading.hide();
        if (res.code === 0) {
          const callbackFunc = {
            // Indicates load ad resource from OpenAD platform, false will be returned if there is no resource to be loaded for the publisher slot/zone
            adResourceLoad: e => {
              // 'step1', e = ture / false
            },
            // Indicates the interactive ad is opening
            adOpening: e => {
              // 'step2', e = ture / false
            },
            // Indicates the interactive ad is opened,
            adOpened: e => {
              // 'step3',  e = ture / false
              if (e && !item.completed) this.checkExtralTask(item, adv);
            },
            // indicates the interactive ad task is finished, the task is defined by publisher
            adTaskFinished: e => {
              // 'step5',  e = viewAD / click  / close
            },
            // Indicates the interactive ad is closing
            adClosing: e => {
              // 'step6', e = viewAD / click  / close
            },
            // Indicates the interactive ad is closed
            adClosed: e => {
              // 'step7', e = viewAD / click / close
              // If you want to perform different steps based on different shutdown states, please write the code here.
            },
            // Indicates clicked and jumps
            adClick: e => {
              // 'step4', e = ture / false
            }
          };

          // Call SDK method to get advertising and render data
          window.openADJsSDK.interactive.getRender({
            adInfo,
            cb: callbackFunc
          });
        }
        return;
      } else if ((item.type || '') == 'matrix') {
        if (!item.completed) this.$router.push(`/taskDetail?id=${item.id}`);
      } else if ((item.type || '') == 'adcloud' || (item.provider || '') == 'adcloud') {
        this.WebApp.openLink(item.url);
      }
      // else{
      //   this.WebApp.openLink(item.url)
      // }
      // this.checkNum = 0;
      // if(!item.completed)
      //   this.checkExtralTask(item,adv);

      this.itemData = item;
      this.itemData.adv = adv;
      this.popEarnTipShow = true;
    },
    async checkExtralTask(item, adv) {
      if (this.checkNum >= 5) {
        this.checkNum = 0;
        return;
      }
      this.checkNum++;
      let rs = await this.$http.post("/task/complete", {
        taskId: item.id,
        adv: adv
      });
      if (rs.code == 0) {
        item.completed = 1;
        if (adv == 1 && item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        if (adv == 0) {
          await this.loadTaskCount();
          this.checkTask();
        }
      } else if (rs.code == 302) {
        setTimeout(() => {
          this.checkExtralTask(item, adv);
        }, 2000);
      }
    },
    checkTask() {
      this.taskNum = 0;
      this.extraTaskNum = 0;
      if (!this.userData.joined_wallet) this.taskNum += 1;
      for (let key in this.setting.socials) {
        if (this.setting.socials[key]) {
          switch (key) {
            case "channel":
              if (this.userData.joined_channel == 0) {
                this.taskNum += 1;
              }
              break;
            case "discord":
              if (this.userData.joined_discord == 0) {
                this.taskNum += 1;
              }
              break;
            case "telegram":
              if (this.userData.joined_telegram == 0) {
                this.taskNum += 1;
              }
              break;
            case "twitter":
              if (this.userData.joined_twitter == 0) {
                this.taskNum += 1;
              }
              break;
          }
        }
      }
      this.taskList.map(item => {
        if (item.completed == 0) {
          this.extraTaskNum += 1;
        }
      });
    },
    onTab(t) {
      this.setSelectTab(t);
    },
    countDown() {
      clearTimeout(this.timeVal);
      this.advList.map(item => {
        if (item.cyclical == 1 && item.countdown > 0) {
          item.countdown = item.countdown - 1;
          if (item.countdown <= 0) {
            item.completed = 0;
          }
        }
      });
      this.taskList.map(item => {
        if (item.cyclical == 1 && item.completed == 1) {
          item.countdown = item.countdown - 1;
          if (item.countdown <= 0) {
            item.completed = 0;
          }
        }
      });
      this.getFadvList();
      this.timeVal = setTimeout(() => {
        this.countDown();
      }, 1000);
    },
    advCountDown() {
      clearTimeout(this.stimeVal);
      this.itemData.adv_countdown = this.itemData.adv_countdown - 1;
      if (this.itemData.adv_countdown <= 0) {
        this.itemData.completed = 1;
        this.checkExtralTask(this.itemData, this.itemData.adv);
      } else {
        this.stimeVal = setTimeout(() => {
          this.advCountDown();
        }, 1000);
      }
    },
    getFadvList() {
      this.fadvList = [];
      this.advList.map(item => {
        if (item.completed !== 1 && this.fadvList.length < 4) {
          this.fadvList.push(item);
        }
      });
    }
  },
  async mounted() {
    if (this.taskList.length == 0) await this.getTaskList();
    this.checkTask();
    await this.getAdvList();
    this.countDown();
  }
};